import React, { useEffect, useState } from "react"
import ButtonSolution from "../buttonSolution/ButtonSolution"
import InformationPagination from "../informationPagination/InformationPagination"
import InputCustom from "../inputCustom/inputCustom"
import { useStaticQuery, graphql } from "gatsby"
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import "./index.scss"
import utils from "../../utils/utils"
import tracking from "../../utils/tracking"
import { LocalStorage } from "ttl-localstorage"
import links from "../../utils/links"

const civil = [
  { value: "Mme", label: "Madame" },
  { value: "M", label: "Monsieur" },
]
const listConsent = ["consent1", "consent2"]

interface Props {
  stepName?: string
  loadLead: boolean
  handleSelectPage: (e: Number) => any
  handleResultOffre: (e) => any
}
const regNumber = /^\d+$/

const DiagnosticInfosContact = ({
  loadLead,
  stepName = "informations",
  handleSelectPage,
  handleResultOffre,
}: Props) => {

  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const dataLocalStorage =
    typeof window !== `undefined` ? LocalStorage.get("save_info_lead") : null
  const dataGraphQl = useStaticQuery(graphql`
    query {
      nodeInfoLeadMentionsLegales {
        field_mentions_legales {
          value
        }
      }
    }
  `)
  const [credentials, setCredentials] = useState({
    informations: {
      civilite: {
        isForm: false,
        value: "",
        pass: null,
        isRequired: true,
        error: "",
        trackName: "",
      },
      lastname: {
        isForm: true,
        value: "",
        error: "",
        pass: null,
        isRequired: true,
        type: "text",
        label: "Nom",
        id: "lastname",
        placeholder: "Votre nom",
        trackName: "",
      },
      firstname: {
        isForm: true,
        value: "",
        error: "",
        pass: null,
        isRequired: true,
        type: "text",
        label: "Prénom",
        id: "firstname",
        placeholder: "Votre prénom",
        trackName: "",
      },
    },
    contact: {
      email: {
        isForm: true,
        value: "",
        error: "",
        pass: null,
        isRequired: true,
        type: "email",
        label: "Adresse email",
        id: "email",
        placeholder: "Votre email",
        trackName: "user_email",
      },
      tel: {
        isForm: true,
        value: "",
        error: "",
        pass: null,
        isRequired: true,
        type: "tel",
        label: "Numéro de téléphone",
        id: "tel",
        trackName: "user_phone",
        placeholder: "6 00 00 00 00",
        addValue: {
          data: { name: "France", dial_code: "+33", flag: "", code: "FR" },
          toggle: false,
        },
      },
      zip: {
        isForm: true,
        value: "",
        error: "",
        pass: null,
        isRequired: true,
        type: "text", //RELEASE-1
        label: "Code postal",
        id: "zip",
        trackName: "user_zip_code",
        placeholder: "Votre code postal",
      },
      recap: {
        isForm: true,
        value: false,
        error: "",
        pass: null,
        isRequired: true,
        type: "checkbox",
        label: "Récapitulatif de votre simulation",
        id: "recap",

        placeholder:
          "Je souhaite voir le résultat de ma simulation et accepte d'être contacté par Nexecur Protection",
      },
      consent1: {
        isForm: false,
        value: false,
        error: "",
        pass: null,
        isRequired: false,
        type: "checkbox",
        label: "",
        id: "consent1",
        trackName: "diagnosis_opt_in_partner",
        placeholder:
          "J'accepte de recevoir des informations de la part des partenaires de Nexecur Protection (facultatif)",
      },
      consent2: {
        isForm: false,
        value: false,
        error: "",
        pass: null,
        isRequired: false,
        type: "checkbox",
        label: "",
        id: "consent2",
        trackName: "diagnosis_opt_in_newsletter",
        placeholder:
          "Je souhaite m'abonner à la newsletter Nexecur Protection (facultatif)",
      },
    },
  })
  const [isNext, setIsNext] = useState(false)
  const contactLegal =
    dataGraphQl.nodeInfoLeadMentionsLegales.field_mentions_legales.value

  const handleClick = value => {
    const data = {
      ...credentials,
      [stepName]: {
        ...credentials[stepName],
        civilite: {
          ...credentials[stepName].civilite,
          value: value,
          error: "",
          pass: true,
        },
      },
    }
    setCredentials(data)
    handleResult(data)
  }

  function space(str, after) {
    if (!str) {
      return false;
    }
    after = after || 4;
    let v = str.replace(/[^\d0-9]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    return v.replace(reg, function (a) {
      return a + ' ';
    });
  }

  const handleChange = e => {
    const { value, name, type } = e.currentTarget
    let formatValue = value
    let forcedPass = null
    if (type === "checkbox") {
      formatValue = !credentials[stepName][name].value
      forcedPass = !credentials[stepName][name].value ? true : null
    }
    if (type === "tel") {
      if (value.length > 0) {
        if (value.substr(0, 1) !== "0") {
          formatValue = space("0" + value, 2)
        }
        else {
          formatValue = space(value, 2)
        }

        if (e.nativeEvent.inputType === "deleteContentBackward" && formatValue.length % 3 === 0) {
          formatValue = value.slice(0, -1)
        }
      }
    }
    /*const data = {
      ...credentials,
      [stepName]: {
        ...credentials[stepName],
        [name]: {
          ...credentials[stepName][name],
          value: formatValue,
          error: "",
          pass: forcedPass,
        },
      },
    }*/
    if (type === "checkbox") {
      //handleResult(data)
    }

    if (name === "zip") {
      if (value.length !== 0) {
        /** FIX LETTER */
        if (regNumber.test(value) == false) {
          return
        }
      }
    }
    /*** AUTO COLMPLETE */
    handleBlur(formatValue, name)
  }

  const handleResult = (prevData = credentials) => {
    const dataStep = prevData[stepName]
    const dataRequired = Object.keys(dataStep)
      .filter(key => dataStep[key].isRequired)
      .map(e => dataStep[e])
    let access = true
    if (dataRequired) {
      dataRequired.forEach(el => {
        if (access) {
          access = el.pass === null ? false : el.pass
        }
      })
    }
    setIsNext(access)
  }

  const handleChangeCountry = data => {
    setCredentials({
      ...credentials,
      contact: {
        ...credentials.contact,
        tel: { ...credentials.contact.tel, addValue: data },
      },
    })
  }

  const checkStatusField = (value, name) => {
    if (value && name) {
      let valueFormat = value
      if (name == "tel" && value.length > 0) {
        valueFormat =
          credentials.contact[name].addValue.data.dial_code +
          value.replaceAll(" ", "").trim()
      }
      const { checkPass, checkError } = utils.blurInput(name, valueFormat)
      return { checkPass, checkError }
    }
    return { checkPass: null, checkError: "" }
  }

  const handleBlur = (value, name) => {
    const { checkPass, checkError } = checkStatusField(value, name)
    const saveData = credentials
    const data = {
      ...saveData,
      [stepName]: {
        ...saveData[stepName],
        [name]: {
          ...saveData[stepName][name],
          value: value,
          pass: checkPass,
          error: checkError,
        },
      },
    }

    setCredentials(prev => ({
      ...prev,
      [stepName]: {
        ...prev[stepName],
        [name]: {
          ...prev[stepName][name],
          value: value,
          error: checkError,
          pass: checkPass,
        },
      },
    }))
    handleResult(data)
    return
  }

  const handleBack = () => {
    if (stepName === "informations") {
      const getLocal = localStorage.getItem("diagnostic")
      if (getLocal) {
        const localTransform = JSON.parse(getLocal)
        if (localTransform) {
          const { data } = localTransform
          if (data) {
            const getLastData = data[data.length - 1]
            const { step, value } = getLastData
            window.location.href = `/diagnostic/?page=questions&step=${step}&value=${value}` + campagne.replace("?","&")
            return
          }
        }
      }
      window.location.href = `/diagnostic/?page=questions&step=1` + campagne.replace("?","&")
      return
    }
    handleSelectPage(stepName === "informations" ? 0 : 1)
  }

  const handleNext = () => {
    const currentInfo = credentials[stepName]
    const keyNoSuccess = Object.keys(currentInfo).filter(
      k =>
        currentInfo[k].isRequired &&
        (currentInfo[k].pass === null || currentInfo[k].pass === false)
    )
    if (keyNoSuccess.length > 0) {
      keyNoSuccess.forEach(key => {
        currentInfo[key].error = utils.correspondanceError[key].error
      })
      setCredentials({ ...credentials, [stepName]: currentInfo })
      return
    } else {
      if (stepName === "informations") {
        tracking.trackDiagInfo(
          "page.display",
          "/diagnostic/contact" + (isTherecampagne ? "/" + campagne : ""),
          null,
          "diagnostic_informations"
        )
        handleSelectPage(2)
      }
      if (stepName === "contact") {
        /** REdirect To RESULT */
        handleResultOffre(credentials)
      }
    }
  }
  const classStyle = el => {
    const { error, value, pass } = el
    return utils.classStyleInput(pass, error, value)
  }

  useEffect(() => {
    Object.keys(credentials).forEach(key => {
      const _currentCredential = credentials[key]
      Object.keys(_currentCredential).forEach(key_current => {
        /** ALL DATA NO TEL */
        if (key_current !== "tel") {
          const getValueLocalStorage = utils.fillData(
            dataLocalStorage,
            key_current
          )
          if (getValueLocalStorage) {
            setCredentials(prev => ({
              ...prev,
              [key]: {
                ...prev[key],
                [key_current]: {
                  ...prev[key][key_current],
                  value: getValueLocalStorage,
                  error: checkStatusField(getValueLocalStorage, key_current)
                    .checkError,
                  pass: checkStatusField(getValueLocalStorage, key_current)
                    .checkPass,
                },
              },
            }))
          }
        } else {
          const tel_value = utils.fillData(
            dataLocalStorage,
            key_current,
            "tel_value"
          )
          if (tel_value) {
            setCredentials(prev => ({
              ...prev,
              [key]: {
                ...prev[key],
                [key_current]: {
                  ...prev[key][key_current],
                  value: tel_value,
                  addValue: {
                    ...prev[key][key_current].addValue,
                    data: {
                      ...prev[key][key_current].addValue.data,
                      dial_code:
                        utils.fillData(
                          dataLocalStorage,
                          key_current,
                          "tel_dial_code"
                        ) || "+33",
                      code:
                        utils.fillData(
                          dataLocalStorage,
                          key_current,
                          "tel_code"
                        ) || "FR",
                    },
                  },
                  error: checkStatusField(tel_value, key_current).checkError,
                  pass: checkStatusField(tel_value, key_current).checkPass,
                },
              },
            }))
          }
        }
      })
    })
  }, [])

  useEffect(() => {
    handleResult(credentials)
    /* Object.keys(credentials).forEach(key => {
      Object.keys(credentials[key]).forEach(_key => {
        const _nameTrack = credentials[key][_key].trackName
        const _value = credentials[key][_key].value
        tracking.track(_nameTrack, _value)
      })
    })*/
  }, [credentials])

  useEffect(() => {
    /*tracking.trackDiagInfo(
      "page.display",
      stepName === "informations"
        ? "/diagnostic/informations"
        : "/diagnostic/contact"
    )*/
    /** CHeck refresh */
    const dataInformations = credentials.informations
    const formatInformations = Object.keys(dataInformations).filter(
      key =>
        dataInformations[key].isRequired &&
        (dataInformations[key].pass === null ||
          dataInformations[key].pass === false)
    )
    /*Object.keys(credentials[stepName]).forEach(key =>
      handleBlur(credentials[stepName][key].value, key)
    )*/
    if (formatInformations.length > 0) {
      window.history.replaceState(
        {},
        "",
        window.location.pathname + "?page=informations" + campagne.replace("?","&")
        
      )
      handleSelectPage(1)

      return
    }
    window.history.replaceState(
      {},
      "",
      window.location.pathname + "?page=" + stepName + campagne.replace("?","&")
    )
    handleResult(credentials)
  }, [stepName, campagne])

  return (
    <div className="container-dia-question d-f fd-c ai-c jc-c ">
      <div className="container-information ">
        <div className="content w-100 box-shadow-m d-s d-f fd-c br-22">
          <div className="container br-22">
            <h3 className="fs-24 ff-gb lh-32 cg-7 ta-l">
              {stepName === "informations" ? "Civilité" : "Contact"}
            </h3>
            <p className="ff-gbook fs-14 lh-24 ta-l">
              Tous les champs sont obligatoires
            </p>
            {stepName === "informations" && (
              <>
                <div
                  className={
                    "container-solution " +
                    classStyle(credentials.informations.civilite)
                  }
                >
                  <ul className={"ul solution d-f fd-md-c"}>
                    {civil.map((el, index) => (
                      <li
                        key={index}
                        className={
                          index + 1 !== civil.length
                            ? "li mr-1 mr-md-0 mb-md-1"
                            : "li "
                        }
                      >
                        <ButtonSolution
                          addClass={
                            el.value === credentials[stepName].civilite.value
                              ? "is-active"
                              : ""
                          }
                          handleClick={() => handleClick(el.value)}
                          value={el.value}
                          label={el.label}
                        />
                      </li>
                    ))}
                  </ul>
                  {credentials.informations.civilite.error && (
                    <p className="c-e1 mb-0 mt-2 lh-14 ta-c ff-gbook fs-14 d-f ai-c jc-c">
                      <span className=" icon icon-before-20 icon-warning mr-1"></span>
                      {credentials.informations.civilite.error}{" "}
                    </p>
                  )}
                </div>
              </>
            )}
            {Object.keys(credentials[stepName])
              .filter(e => credentials[stepName][e].isForm)
              .map(key => (
                <div key={key} className="pt-3">
                  <InputCustom
                    handleChangeCountry={handleChangeCountry}
                    classContainer={classStyle(credentials[stepName][key])}
                    data={credentials[stepName][key]}
                    handleChange={e => handleChange(e)}
                    handleBlur={(value, name) => handleBlur(value, name)}
                    input={{
                      type: credentials[stepName][key].type,
                      id: credentials[stepName][key].id,
                      placeholder: credentials[stepName][key].placeholder,
                    }}
                  />
                </div>
              ))}
            {stepName === "contact" && (
              <>
                <div
                  className="w-100 bcg-10 mt-3 mb-2"
                  style={{ height: "1px" }}
                ></div>
                {listConsent.map((key, index) => (
                  <div key={index} className="py-1">
                    <InputCustom
                      handleChangeCountry={() => { }}
                      classContainer={classStyle(credentials[stepName][key])}
                      data={credentials[stepName][key]}
                      handleChange={e => handleChange(e)}
                      handleBlur={e => handleBlur(e, "")}
                      input={{
                        type: credentials[stepName][key].type,
                        id: credentials[stepName][key].id,
                        placeholder: credentials[stepName][key].placeholder,
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        <div className="w-100 mt-5 ">
          <InformationPagination
            loadLead={loadLead}
            isNext={isNext}
            handleBack={handleBack}
            handleNext={handleNext}
            labelNext={
              stepName === "informations" ? "Étape Suivante" : "Voir mon offre"
            }
          />
        </div>
        {stepName === "contact" && (
          <div
            dangerouslySetInnerHTML={{ __html: contactLegal }}
            className="legal-wording mt-7 mt-md-6"
          ></div>
        )}
      </div>
    </div>
  )
}

export default DiagnosticInfosContact
