import React, { useRef, useEffect } from "react"
import { QuestionPaginationProps } from "../../models/diagnostic"
import TooltipButton from "../TooltipButton/TooltipButton"

const QuestionPagination: React.FC<QuestionPaginationProps> = ({
  formQuestion,
  selectStepQuestion,
  setDomPagination,
  selectQuestionByStep,
}) => {
  const getCurrentQuestion = formQuestion.filter(el => el.isActive === true)
    ? formQuestion.filter(el => el.isActive === true)[0]
    : null
  const lastStep = formQuestion[formQuestion.length - 1]
    ? formQuestion[formQuestion.length - 1].step
    : null
  const isLastStep = getCurrentQuestion
    ? lastStep === getCurrentQuestion.step
      ? true
      : false
    : false
  const targetDom = useRef(null)
  const handleChangeStep = question => {
    const { value, prevValue, nextStep, step } = question
    if (value && prevValue) {
      if (value.value === prevValue.value) {
        selectQuestionByStep(nextStep)
        return
      }
    } else if (!value && !prevValue) {
      selectStepQuestion(false)
      return
    }
    selectStepQuestion(nextStep)
  }
  const styleNextbtn = () => {
    const isActive =
      getCurrentQuestion.nextStep !== false && getCurrentQuestion.prevValue
        ? "is-active"
        : ""
    return (
      "btn-pagination-step d-f ai-c diagnostic icon-arrow-pagination-next next fs-14 tooltip ml-a " +
      isActive
    )
  }
  useEffect(() => {
    setDomPagination(targetDom)
  }, [])

  return (
    <div ref={targetDom} className="d-f fd-md-cr ai-md-c  jc-sb">
      {getCurrentQuestion && (
        <>
          {getCurrentQuestion.prevStep && (
            <button
              onClick={() => selectQuestionByStep(getCurrentQuestion.prevStep)}
              className="btn-pagination-step diagnostic prev fs-14"
            >
              Question précédente
            </button>
          )}
          <TooltipButton isActive={!getCurrentQuestion.nextStep ? true : false}>
            <button
              onClick={() => handleChangeStep(getCurrentQuestion)}
              className={styleNextbtn()}
            >
              {isLastStep ? "Étape suivante" : "Question suivante"}
            </button>
          </TooltipButton>
        </>
      )}
    </div>
  )
}

export default QuestionPagination
