import React, { useRef, useEffect } from "react"
import Didactitiel from "./didactitiel/Didactitiel"
import { QuestionProps } from "../../models/diagnostic"
import InputNumber from "./inputNumber/InputNumber"
import ButtonSolution from "../buttonSolution/ButtonSolution"
import "./questionDiagnostic.scss"

const QuestionDianostic: React.FC<QuestionProps> = ({
  formQuestion,
  updateFormQuestion,
  error,
  setDomQuestion,
}) => {
  const currentQuestion = formQuestion.filter(e => e.isActive === true)
    ? formQuestion.filter(e => e.isActive === true)[0]
    : null
  const iconCheck = {
    yes: "icon-check-valid",
    no: "icon-check-no-valid",
    apartment: "icon-appartment",
    house: "icon-home",
    other: "icon-shop",
  }
  const targetDom = useRef(null)
  const addClass = value => {
    if (currentQuestion.prevValue) {
      return currentQuestion.prevValue.value === value ? true : false
    }
    return false
  }
  useEffect(() => {
    setDomQuestion(targetDom)
  }, [])
  return (
    <>
      {currentQuestion && (
        <>
          <div ref={targetDom} className="question-container box-shadow-m br-22 bc-w mb-4 ">
            <p
              className={
                currentQuestion.label_instruction
                  ? "lh-32 fs-24 ta-c mb-2"
                  : "lh-32 fs-24 ta-c mb-5"
              }
            >
              {currentQuestion.label}
            </p>
            {currentQuestion.label_instruction && (
              <p className="fs-16 c-b ff-om ta-c mb-5">
                {currentQuestion.label_instruction}
              </p>
            )}

            {/** TODO SWITCH BY TYPE */}
            <div
              className={
                error
                  ? "content is-error d-f fd-md-c ai-c jc-c  mb-5 "
                  : "content d-f fd-md-c ai-c jc-c  mb-5 "
              }
            >
              {currentQuestion.properties.type === "select" && (
                <>
                  {currentQuestion.properties.data.map((el, key) => (
                    <ButtonSolution
                      key={key}
                      value={el.value}
                      label={el.label}
                      isActive={addClass(el.value)}
                      handleClick={() =>
                        updateFormQuestion(currentQuestion.step, el)
                      }
                      addClass={addClass(el.value) ? " is-active" : ""}
                    />
                  ))}
                </>
              )}
              {currentQuestion.properties.type === "number" && (
                <InputNumber
                  limitRange={7}
                  value={currentQuestion.prevValue}
                  handleChange={v =>
                    updateFormQuestion(currentQuestion.step, v)
                  }
                />
              )}
            </div>
            {error && (
              <div style={{ height: "14px", marginBottom: "42px" }}>
                <p className="c-e1 mb-0 lh-14 ta-c ff-gbook fs-14 d-f ai-c jc-c">
                  <span className=" icon icon-before-20 icon-warning mr-1"></span>
                  {error}
                </p>
              </div>
            )}
            {currentQuestion.pedagogy && (
              <Didactitiel data={currentQuestion.pedagogy} />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default QuestionDianostic
