import React from 'react';
import {propsPedagogy} from "../../../models/diagnostic";
import './didactitiel.scss';


const Didactitiel :React.FC <propsPedagogy>  = ({data}) => {
    return (
        <div className="didact bcg-2  br-15 d-f fd-r fd-md-c ai-c">
            <div>
                <img src={`${data.icon}`} alt="picto"></img>
            </div>
            <div className="c-b fs-14 ml-1 ml-0 mt-1">
                {data.texts.length > 1 ?(
                    <ul className="pl-3 mt-0 mb-0">
                        {data.texts.map((text,index)=>(
                            <li key={index} className="lh-24">{text}</li>
                        ))}
                    </ul>
                ):(
                    <p className="mb-0 ff-m ta-md-c lh-md-24">{data.texts[0]}</p>
                )}
                
               
            </div>
        </div>
    );
};

export default Didactitiel;