import React from "react"
import MinLoader from "../minLoader/minLoader"
import TooltipButton from "../TooltipButton/TooltipButton"

const InformationPagination = ({
  loadLead,
  isNext,
  handleBack,
  handleNext,
  labelNext,
  havePrev = true,
  haveNext = true,
}) => {
  const styleNextbtn = () => {
    const isActive = isNext ? " is-active" : ""
    const isLoading = loadLead ? "is-load" : ""
    return (
      "btn-pagination-step d-f ai-c diagnostic icon-arrow-pagination-next next fs-14 tooltip ml-a d-f jc-c " +
      isActive +
      " " +
      isLoading
    )
  }
  return (
    <div className="d-f fd-md-cr ai-md-c  jc-sb">
      {havePrev && (
        <button
          onClick={() => handleBack()}
          className="btn-pagination-step diagnostic prev fs-14"
        >
          Précédent
        </button>
      )}
      {haveNext && (
        <TooltipButton
          label={"Veuillez remplir tous les champs"}
          isActive={!isNext ? true : false}
        >
          <button onClick={() => handleNext()} className={styleNextbtn()}>
            {loadLead ? (
              <>
                <div className="w-100 d-f j-c ai-c">
                  <MinLoader />
                </div>
              </>
            ) : (
              <>{labelNext}</>
            )}
          </button>
        </TooltipButton>
      )}
    </div>
  )
}

export default InformationPagination
