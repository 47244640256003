import React, { useEffect, useState } from "react"
import diagnostics from "../../utils/diagnostics"
import "./navigationDiagnostic.scss"

const NavigationDiagnostic = ({
  formQuestion,
  formatList,
  selectStepQuestionByNav,
}) => {
  const [screen, setScreen] = useState(false)
  const [toogleNav, setToogleNav] = useState(false)
  const mediaScreenLg = 1170
  const isActive = element => {
    if (element.isActive) {
      return "is-active"
    }
    if (element.value) {
      return "is-pass"
    }
    return ""
  }
  const thumbStyle = () => {
    const windowWidth = window.innerWidth
    const standardHeight = 40
    const standardWidth = 21
    let mediaScreenCurrent = windowWidth > mediaScreenLg ? "height" : "width"
    let valueStyle =
      windowWidth > mediaScreenLg ? standardHeight : standardWidth
    let lastIndexQuestionHadValue = 0
    /** Is OPEN ON Mobile */
    if (toogleNav && screen) {
      mediaScreenCurrent = "height"
      valueStyle = standardHeight
    }
    /*** DESKTOP */
    if (formQuestion) {
      formQuestion
        .filter(el => el.isVisible)
        .forEach((element, index) => {
          if (element.value || element.isActive)
            lastIndexQuestionHadValue = index
        })
      if (lastIndexQuestionHadValue == 0) {
        return { [mediaScreenCurrent]: valueStyle + "px" }
      } else {
        return {
          [mediaScreenCurrent]:
            (lastIndexQuestionHadValue + 1) * valueStyle + "px",
        }
      }
    }
  }
  const navStyle = () => {
    const isToogle = toogleNav ? "is-open" : ""
    return "navigation-diagnostic " + isToogle
  }
  const allowChangeStep = (step, questions) => {
    const currentStep = questions.filter(e => e.isActive === true)
    const lastStep = formQuestion[formQuestion.length - 1]
      ? formQuestion[formQuestion.length - 1].step
      : null
    if (currentStep.length > 0) {
      if (currentStep[0].step === lastStep) {
        return true
      }
    }
    if (typeof questions[step - 1].nextStep === "number") {
      return true
    }
    return false
  }
  const handleSelect = step => {
    if (formatList && allowChangeStep(step, formQuestion)) {
      const _getStepCurrent = diagnostics.selectStepOnNavigation(
        formatList,
        step
      )
      _getStepCurrent !== null && selectStepQuestionByNav(_getStepCurrent)
    }
  }
  const getCurrentLabel = () => {
    if (formatList) {
      const stepCurrent = formatList.filter(e => e.isActive)
      if (stepCurrent.length > 0) {
        return stepCurrent[0].key
      }
    }
    return ""
  }
  const getIndexCurrent = () => {
    return formatList.findIndex((element, index) => element.isActive) + 1
  }
  /** EVENT RESIZE */
  const startScreen = () => {
    const windowWidth = window.innerWidth
    /** DESKTOP */
    if (windowWidth > mediaScreenLg) {
      if (screen) {
        setScreen(false)
      }
    } else {
      if (!screen) {
        setScreen(true)
      }
    }
  }
  useEffect(() => {
    startScreen()
    window.addEventListener("resize", () => {
      startScreen()
    })
  }, [screen, toogleNav])

  return (
    <div className="wrapper-diagnostic-navigation">
      <div className=" p-r">
        {screen && (
          <p className="c-1 ff-gb mb-1">
            {toogleNav
              ? "Questions"
              : "Question " +
                getIndexCurrent() +
                " : " +
                getCurrentLabel()}{" "}
          </p>
        )}
        <ul className={navStyle()}>
          <div className="thumb" style={thumbStyle()}></div>
          {formatList &&
            formatList.map((el, index) => (
              <li
                key={index}
                className={"li item d-f ai-c py-1 " + isActive(el)}
              >
                <span
                  onClick={() => handleSelect(el.step)}
                  className={"bubble z-1"}
                ></span>
                <div className="detail-text d-f ai-c ">
                  <button
                    onClick={() => handleSelect(el.step)}
                    className={"ml-1 link w-s-n fs-12"}
                  >
                    {el.key}
                  </button>{" "}
                  {el.value && (
                    <>
                      {el.value.label && (
                        <span className="c-1 ml-1 w-s-n value ">
                          {" "}
                          : {el.value.label}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </li>
            ))}
        </ul>
        {screen && (
          <button
            onClick={() => setToogleNav(!toogleNav)}
            className={
              toogleNav
                ? "btn btn-open-nav ml-a bc-2 d-f jc-c ai-c br-50 is-rotate"
                : "btn btn-open-nav ml-a bc-2 d-f jc-c ai-c br-50"
            }
            style={{ height: "40px", width: "40px" }}
          >
            <span className="icon-arrow-down-c1"></span>
          </button>
        )}
      </div>
    </div>
  )
}

export default NavigationDiagnostic
