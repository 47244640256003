import tracking from "./tracking"

interface questionsFormat {
  step: Number
}

const correspondance = {
  questions: 0,
  informations: 1,
  contact: 2,
}

function getCorrespondance(page: string) {
  return correspondance.hasOwnProperty(page) ? correspondance[page] : null
}

function selectStepOnNavigation(data: [questionsFormat], step: number) {
  const stepSelect = data.filter(e => e.step === step)
  return stepSelect ? stepSelect[0].step : null
}

function trackDiag(dataLocal) {
  /** Tracking */
  if (dataLocal) {
    if (Object.keys(dataLocal).length > 0) {
      Object.keys(dataLocal).forEach(key => {
        tracking.track(key, dataLocal[key])
      })
    }
  }
}

export default {
  trackDiag,
  correspondance,
  getCorrespondance,
  selectStepOnNavigation,
}
