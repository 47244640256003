import React from "react"
import "./steps_diagnostic.scss"
import "../../assets/styles/components/_icon.scss"

interface Props {
  index: number
  data: Array<Data>
  handleSelect: (e: Number) => any
}
interface Data {
  label: string
}

const StepsDiagnostic: React.FC<Props> = ({ index, data, handleSelect }) => {
  const classAdd = el_index => {
    let base = "item  d-f ai-c icon "
    if (el_index !== 0) base += " icon-arrow-grey"
    if (index === el_index) return base + " is-current"
    if (index > el_index) return base + " is-active"
    return base
  }
  return (
    <ul className="steps-diagnostic ul d-f ai-c mx-md-a">
      {data.map((el, el_index) => (
        <li key={el_index} className="li">
          <button
            onClick={() => {
              handleSelect(el_index)
            }}
            className={classAdd(el_index)}
          >
            {el_index < index ? (
              <div>
                <span className="index icon-index-step d-b d-f jc-c ai-c">
                  <span className="icon-check-white"></span>
                </span>
              </div>
            ) : (
              <span className="index d-f jc-c ai-c">{el_index + 1}</span>
            )}
            <p className="label mb-0 pl-md-2 pr-md-0">{el.label}</p>
          </button>
        </li>
      ))}
    </ul>
  )
}

export default StepsDiagnostic
