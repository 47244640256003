import React from "react"

const ButtonSolution = ({
  value,
  label,
  isActive = false,
  handleClick = null,
  addClass = "btn-choice diagnostic mr-md-0 mb-md-1 box-shadow-s",
  url = "/",
}) => {
  const iconCheck = {
    yes: "icon-check-valid",
    no: "icon-check-no-valid",
    apartment: "icon-appartment",
    house: "icon-home",
    other: "icon-shop",
  }

  const checkIsActive = () => {
    return handleClick !== null
      ? "btn-choice diagnostic mr-1 mr-md-0 mb-md-1 " + addClass
      : addClass
  }
  const hasIcon = () => {
    return iconCheck[value] !== undefined
      ? iconCheck[value] + (isActive ? " is-active" : "")
      : ""
  }
  return (
    <button
      onClick={() => {
        handleClick ? handleClick() : (window.location.href = url)
      }}
      className={checkIsActive()}
    >
      <span className="tLeft">
        {iconCheck.hasOwnProperty(value) && (
          <span className={hasIcon() + " mr-1"} style= {{height: "20px", width: "20px"}}></span>
        )}
        <span
          className={
            iconCheck.hasOwnProperty(value)
              ? "mr-md-a text d-b ff-gm c-4 w-s-n "
              : "mx-md-a text d-b ff-gm c-4 w-s-n "
          }
        >
          {label}
        </span>
      </span>
      
    </button>
  )
}

export default ButtonSolution
