import React, { useEffect } from 'react';
import '../../../assets/styles/components/_icon.scss';
import './inputNumber.scss'

const InputNumber = ({value,handleChange,limitRange=7}) => {
    const numberCurrent = value ? value.value : 1;
    const isVad = numberCurrent > 3 ? false : true;
    const changeValue = (value)=>{
        const data = {
            additional: {type: 'douv', value: value},
            prevValue:value,
            value:value,
            label:value,
            vad: value > 3 ? false : true 
        }
        handleChange(data)
    }
    useEffect(()=>{
        handleChange({additional: {type: 'douv', value: numberCurrent},value:numberCurrent,label:numberCurrent,vad:isVad});
    },[])
    return (
        <div className="container-input-number d-f jc-sb ai-c ">
            <button onClick={()=>changeValue((numberCurrent)-1>=1 ? (numberCurrent)-1 : 1)} className={numberCurrent > 1 ? "btn-input-number icon-input-number remove is-active": "btn-input-number icon-input-number remove" }></button>
            <div className="content-value">
                <input type="number" className="d-n" onChange={(e)=>handleChange( {label:parseInt(e.currentTarget.value),value:parseInt(e.currentTarget.value)} ) } value={numberCurrent}></input>
                <p className="fs-16 c-b mb-0">{numberCurrent}</p>
            </div>
            <button onClick={()=>changeValue((numberCurrent)+1) } className="btn-input-number icon-input-number add is-active"></button>
        </div>
    );
};

export default InputNumber;