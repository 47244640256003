import React from 'react';
import Tooltip from '@mui/material/Tooltip';


const TooltipButton = ({label="Veuillez sélectionner un choix pour passer à la suite ",children,isActive=true}) => {
    if(isActive == false){
        return (<> {children}</>)
    }
    /**<div role="tooltip" id="mui-1" class="MuiTooltip-popper MuiTooltip-popperInteractive css-1twearc-MuiTooltip-popper" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(312px, 659px, 0px);" data-popper-placement="bottom">
     * <div class="MuiTooltip-tooltip MuiTooltip-touch MuiTooltip-tooltipPlacementBottom css-1l70ank-MuiTooltip-tooltip" style="opacity: 1; transform: none; transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;">
     * Veuiller sélectionner un choix pour passer à la suite </div></div> */
   /** <div ref={toolTipRef} style={position} className="tooltiptext ff-gm"><p className="text fs-12 c-b ta-c mb-0">{label}</p></div> */
    return (
        <>
            <Tooltip title={label}>
                {children}
            </Tooltip>
        </>
    );
};

export default TooltipButton;